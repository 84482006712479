<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->

      <el-form ref="form" label-width="80px" size="mini">
        <div>
          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="getdeptlist()"
            >刷新</el-button
          >
          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="newdept()"
            >新增</el-button
          >
        </div>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix"></div>
      <div class="chongqi">
        <el-table
          :data="tableData"
          height="1150"
          row-key="deptId"
          default-expand-all
          stripe
          style="width: 100%"
          class="list-table"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="name" label="名称" min-width="50%">
          </el-table-column>

          <el-table-column label="类型" min-width="50%">
            <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
            <template slot-scope="scope">
              <el-tag :type="urgentStatus[scope.row.type].type">{{
                urgentStatus[scope.row.type].text
              }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column min-width="20%" label="操作" fixed="right">
            <template slot-scope="scope">
              <div>
                <el-tooltip
                  content="添加下属部门"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="newdept(scope.row)"
                    circle
                    icon="el-icon-circle-plus"
                    type="primary"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="删除"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="delectdept(scope.row)"
                    circle
                    icon="el-icon-delete"
                    type="primary"
                  ></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      title="更新状态"
      width="550px"
      :visible.sync="showadd"
      append-to-body
    >
      <el-form label-width="120px">
        <el-form-item label="部门名称">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item label="部门类型">
          <el-select v-model="addform.type" placeholder="请选择">
            <el-option
              v-for="item in typeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="success" plain @click="adddept">添加</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdeptlisturl,
  adddeptlisturl,
  deldeptlisturl,
} from "@/api/jurisdiction";
import { translateDataToTree } from '@/utils/setMethods'

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      urgentStatus: [
        { status: 0, text: "内部", type: "info" }, // 1
        { status: 1, text: "外部", type: "primary" }, // 1
      ],
      showadd: false,
      addform: {
        type: "0",
        name: "",
        parentId: "0",
      },
      typeoptions: [
        {
          value: "0",
          label: "内部",
        },
        {
          value: "1",
          label: "外部",
        },
      ],
    };
  },
  mounted() {
    this.getdeptlist();
  },

  created() {},
  methods: {
    async delectdept(row, column, event) {
      try {
        const res = await deldeptlisturl({ deptId: row.deptId });
        if (res.data.code === 200) {
          this.getdeptlist();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    newdept(row, column, event) {
      if (row) {
        this.addform.parentId = row.deptId;
      } else {
        this.addform.parentId = "0";
      }
      this.showadd = true;
    },
    async adddept() {
      try {
        const res = await adddeptlisturl(this.addform);
        if (res.data.code === 200) {
          this.getdeptlist();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showadd = false;
      }
    },
    async getdeptlist() {
      try {
        const res = await getdeptlisturl();
        if (res.data.code === 200) {
          this.tableData = translateDataToTree(res.data.data);
          console.log(this.tableData);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
  },
};
</script >

<style scoped lang="less">
.header {
  float: right;
}
.list-table {
  margin-bottom: 20px;
}
.filter-card {
  margin-bottom: 3px;
}
.demo-input-suffix {
  display: flex;
  align-items: center;
  .input {
    width: 150px;
  }
}
</style>
